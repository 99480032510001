window.addEventListener('DOMContentLoaded', event => {

    const navbarToggle = document.body.querySelector('#toggleNavbar');
    const topbar = document.body.querySelector('#topbar');

    if (navbarToggle) {
        navbarToggle.addEventListener('click', event => {
            event.preventDefault();
            topbar.classList.toggle('navbar-toggled');
        });
    }

});
